import JwtDecode from 'jwt-decode';
import DuLogger from './DuLogger';
import { getAdminGroups, getManagerGroups } from '../../../../utils/getConfigData';

const getAccessTokenFromAuthenticationProvider = async (oktaAuth) => {
    const provider = localStorage.getItem('authenticationProvider');
    switch (provider) {
        case 'cognito': {
            const cognitoUser = JSON.parse(localStorage.getItem('cognitouser'));
            return cognitoUser?.signInUserSession?.accessToken?.jwtToken;
        }
        case 'okta':
        default:
            return oktaAuth.getAccessToken();
    }
};

/**
 * @description Function to get expiry date of token
 * @param {*} token Token
 * @return {Date}
 */
const GetExpireDate = function (token) {
    const decodedToken = DecodeToken(token);
    const exp = decodedToken.exp || 0;

    if (exp > 0) {
        return new Date(exp * 1000);
    }

    return null;
};
const isTokenExpired = async (token) => {
    const expiredOn = await GetExpireDate(token);
    return expiredOn < new Date();
};

const getAuthProvider = () => {
    return localStorage.getItem('authenticationProvider');
};

/**
 * @description Function to get token from Authorization header
 * @param {string} authorizationHeader Authorization Header
 * @return {string|null}
 */
const GetTokenFromAuth = (authorizationHeader) => {
    if (typeof authorizationHeader !== 'string') {
        return null;
    }

    return authorizationHeader.includes('Bearer ') || authorizationHeader.includes('Basic ')
        ? authorizationHeader.split(' ')[1]
        : authorizationHeader;
};

/**
 * @description Function to validate a token by decoding it
 * @param {*} token Token object (or) Token value
 * @return {string}
 */
const DecodeToken = function (token) {
    let decodedToken = {};
    const tokenValue = GetTokenValue(token);

    try {
        decodedToken = JwtDecode(tokenValue);
    } catch (error) {
        DuLogger.warn('Found an invalid token, this is only okay if we are logging a user out or give them a not authorized page.', token);
    }

    return decodedToken;
};

/**
 * @description Function to get groups that are assigned to token
 * @param {*} token Token
 * @return {array} Array of strings
 */
const GetGroups = function (token) {
    const decodedToken = DecodeToken(token);

    if (window && window.newrelic) {
        window.newrelic.setCustomAttribute('OktaUserId', decodedToken.uid || '');
    }

    return decodedToken.groups || [];
};

/**
 * @description Function to find if token is part of provided list of groups
 * @param {*} token Token
 * @param {array} allowedGroups List of allowed groups
 * @return {boolean}
 */
const IsInGroup = function (token, allowedGroups) {
    const groups = GetGroups(token);
    return groups.some((group) => allowedGroups.indexOf(group) >= 0);
};

/**
 * @description Function to get email address from token
 * @param {*} token Token
 * @return {string}
 */
const GetEmail = function (token) {
    const provider = localStorage.getItem('authenticationProvider');
    const decodedToken = DecodeToken(token);
    if (provider === 'cognito') {
        //Can change later
        return decodedToken.sub || decodedToken.email || '';
    } else {
        return decodedToken.sub || decodedToken.email || '';
    }
};

/**
 * @description Function to get OktaId from token
 * @param {*} token Token
 * @return {string}
 */
const GetOktaId = function (token) {
    const provider = localStorage.getItem('authenticationProvider');
    const decodedToken = DecodeToken(token);
    if (provider === 'cognito') {
        //Can change later
        return decodedToken.sub || decodedToken.email || '';
    } else {
        return decodedToken.uid || '';
    }
};

/**
 * @description Function to get UserId from token
 * @param {*} token Token
 * @return {string|null}
 */
const GetUserId = function (token) {
    const decodedToken = DecodeToken(token);
    const email = decodedToken.sub || decodedToken.email || '';

    if (email.length > 0) {
        return email.substr(0, email.indexOf('@'));
    }

    return null;
};

/**
 * @description Function to check if token is valid
 * @param {*} token Token
 * @param {number} gracePeriodInMinutes
 * @return {boolean}
 */
const IsTokenValid = function (token, gracePeriodInMinutes) {
    const expDate = GetExpireDate(token);

    if (expDate === null) {
        return false;
    }

    const difference = expDate - new Date();
    return difference >= gracePeriodInMinutes * 60000;
};

/**
 * @description Function to get value of token
 * @param {*} token Token object (or) Token value
 * @return {string}
 */
const GetTokenValue = (token) => {
    if (typeof token === 'object') {
        return token.value || token.accessToken || '';
    }
    return token || '';
};

/**
 * @description Function to get group scope based on environment
 * @param {string} location
 * @return {string}
 */
const GetGroupScope = function (location) {
    let environment;
    const hostname = location ? location : window ? window.location.hostname.toLowerCase() : '';

    if (hostname.includes('dev') || hostname.includes('localhost')) {
        environment = 'dev';
    } else if (hostname.includes('stage')) {
        environment = 'stage';
    } else if (hostname.includes('uat')) {
        environment = 'uat';
    } else if (hostname.includes('qa')) {
        environment = 'qa';
    } else if (hostname.includes('tst') || hostname.includes('test')) {
        environment = 'test';
    } else {
        environment = 'prod';
    }

    return environment;
};

/**
 * @description Function to check if token has write access
 * @param {object} context
 * @param {*} token
 * @param {array} adminGroups
 * @param {array} regularGroups
 * @return {boolean}
 */
const ValidateTokenWithWriteScope = function (context, token, adminGroups, regularGroups) {
    const tokenValue = GetTokenValue(token);

    if (!tokenValue || tokenValue.length <= 0) {
        if (context.state.canWrite === null || context.state.canWrite === true) {
            context.setState({ canWrite: false });
        }

        return false;
    }

    if (window && window.newrelic) {
        window.newrelic.setCustomAttribute('OktaUserId', GetOktaId(token));
    }

    adminGroups = adminGroups ? adminGroups : getAdminGroups();
    regularGroups = regularGroups ? regularGroups : getManagerGroups();

    if (IsInGroup(token, adminGroups) || IsInGroup(token, regularGroups)) {
        if (context.state.canWrite === null || context.state.canWrite === false) {
            context.setState({ canWrite: true });
        }
    } else {
        if (context.state.canWrite === null || context.state.canWrite === true) {
            context.setState({ canWrite: false });
        }
    }

    return true; //Return true because token is valid, don't care about canWrite for the return
};

/** @type {*} */
const DuAuthenticationUtilities = {
    GetTokenFromAuth,
    GetTokenValue,
    GetGroups,
    IsInGroup,
    GetEmail,
    GetOktaId,
    GetUserId,
    GetGroupScope,
    GetExpireDate,
    IsTokenValid,
    DecodeToken,
    ValidateTokenWithWriteScope,
    getAccessTokenFromAuthenticationProvider,
    getAuthProvider,
    isTokenExpired
};

export default DuAuthenticationUtilities;
