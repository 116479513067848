import { POINT_MATRIX_SET_CONSTANTS } from './constants/pointMatrixConstants';

export const urls = {
    POINT_MATRIX: 'pointmatrix'
};

class APIEndPoints {
    static POINT_MATRIX_POST = () => {
        return `/${urls.POINT_MATRIX}`;
    };

    static POINT_MATRIX_GET = (
        page: number,
        size: number,
        districtGroup?: string,
        commodityCode?: string,
        estimateGroup?: string,
        variety?: string,
        attributeType?: string,
        status?: string,
        modifiedBy?: string
    ) => {
        const baseUrl = `/${urls.POINT_MATRIX}`;
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
            businessunit: 'DEMEA'
        });

        if (districtGroup) {
            params.append('districtgroup', districtGroup);
        }

        if (commodityCode) {
            params.append('commoditycode', commodityCode);
        }

        if (estimateGroup) {
            params.append('estimategroup', estimateGroup);
        }

        if (attributeType) {
            params.append('attributetype', attributeType);
        }

        if (status) {
            params.append('status', status);
        } else {
            params.append('status', POINT_MATRIX_SET_CONSTANTS.ALL);
        }

        if (variety) {
            params.append('varietycode', variety);
        }

        if (modifiedBy) {
            params.append('modifiedby', modifiedBy);
        }

        return `${baseUrl}?${params.toString()}`;
    };
}

export default APIEndPoints;
