export enum routesName {
    INITIALIZE_APPLICATION = 'initializeApplication',
    USER_MANAGEMENT = 'users',
    SITES = 'sites',
    CREATE_SITE_MAPPING = 'createSiteMapping',
    EDIT_SITE_MAPPING = 'editSite',
    POINT_MATRIX = 'pointMatrix',
    POINT_MATRIX_CREATE = 'pointMatrixCreate',
    POINT_MATRIX_SET = 'pointMatrixSet',
    POINT_MATRIX_CONFIRM = 'pointMatrixConfirm'
}

export const routes = {
    [routesName.INITIALIZE_APPLICATION]: {
        path: '/InitializeApplication/',
        getRoute: () => '/InitializeApplication/'
    },

    [routesName.USER_MANAGEMENT]: {
        path: 'users',
        getRoute: () => 'users'
    },
    [routesName.SITES]: {
        path: 'sites',
        getRoute: () => 'sites'
    },
    [routesName.CREATE_SITE_MAPPING]: {
        path: 'createSite',
        getRoute: () => 'createSite'
    },
    [routesName.EDIT_SITE_MAPPING]: {
        path: 'editSite/:id',
        getRoute: (siteId: string) => `editSite/${siteId}`
    },
    [routesName.POINT_MATRIX]: {
        path: '/demea/pointMatrix',
        getRoute: () => '/demea/pointMatrix'
    },
    [routesName.POINT_MATRIX_CREATE]: {
        path: 'create',
        getRoute: () => 'create'
    },
    [routesName.POINT_MATRIX_SET]: {
        path: 'set',
        getRoute: () => 'set'
    },
    [routesName.POINT_MATRIX_CONFIRM]: {
        path: 'confirm',
        getRoute: () => 'confirm'
    }
};
