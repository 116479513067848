import {
    DriscollsAppLayout,
    DriscollsAppHeader,
    DriscollsTranslatedValue,
    DriscollsTypography,
    DriscollsTypographyEnum,
    useLang,
    DriscollsTypographyStyles,
    FaIcons,
    IconSize,
    DriscollsMobileNavigation,
    DriscollsTypographyTranslated,
    Language
} from '@driscollsinc/style-guide-web2.0';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../images/Logo_Small_Transparent.png';
import styles from './ApplicationLayout.module.scss';
import { NAMESPACE } from '../../i18n';
import { routes, routesName } from '../routes/routes';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitleAction } from '../../dota/actions/actions';

const DriscollsLogo = () => <img src={logo} alt="driscolls-logo" className={styles.bannerLogo} />;

const ApplicationLayout = () => {
    const navigate = useNavigate();
    const { getCurrentLanguageSelected, changeLanguage } = useLang([NAMESPACE.COMMON]);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageTitleAction('Quality Management System'));
        if (location.pathname.includes('demea')) {
            import('../demea.scss');
        }
    }, [location]);

    const navigation = [
        {
            title: 'User Management',
            description: 'Manage the access of QA managers of the internal users.',
            path: routes[routesName.USER_MANAGEMENT].path,
            ModuleIcon: () => <FaIcons.FaUser size={IconSize.SMALL} className={styles.icon} />,
            isEnabled: true
        },
        {
            title: 'Sites Management',
            description: 'Manage the access of QA Managers',
            path: routes[routesName.SITES].path,
            ModuleIcon: () => <FaIcons.FaSitemap size={IconSize.SMALL} className={styles.icon} />,
            isEnabled: true
        },
        {
            title: 'Point Matrix',
            description: 'Manage the Point Matrix',
            path: routes[routesName.POINT_MATRIX].path,
            ModuleIcon: () => <FaIcons.FaClipboardList size={IconSize.SMALL} className={styles.icon} />,
            isEnabled: true
        }
    ];

    return (
        <DriscollsAppLayout
            applicationTitle={
                <DriscollsTypographyTranslated
                    className={styles.title}
                    type={DriscollsTypographyEnum.H6}
                    typographyStyle={DriscollsTypographyStyles.SUBTITLE1}
                    nameSpacing={NAMESPACE.COMMON}
                >
                    QMP
                </DriscollsTypographyTranslated>
            }
            header={{
                headerClassName: styles.headerContainer,
                drawer: (close: () => void) => {
                    return (
                        <>
                            <DriscollsLogo />
                            <DriscollsMobileNavigation navigationItem={navigation} drawerClose={close} />
                            <DriscollsTypography
                                type={DriscollsTypographyEnum.P}
                                typographyStyle={DriscollsTypographyStyles.SUBTITLE5}
                                className={styles.appVersion}
                            >
                                <DriscollsTranslatedValue value="App Version" nameSpacing={NAMESPACE.COMMON} />[{process.env.REACT_APP_VERSION}]
                            </DriscollsTypography>
                        </>
                    );
                },
                drawerDirection: 'left',
                tools: [
                    <>
                        <DriscollsAppHeader
                            headerClassName={styles.tools}
                            currentLanguage={getCurrentLanguageSelected()}
                            onLanguageChange={(val: typeof Language) => changeLanguage(val)}
                            languages={[Language.ENGLISH, Language.SPANISH]}
                            onLogout={() => navigate('/Logout')}
                            drawerIconClassName={styles.hideIcon}
                        />
                    </>
                ],
                leftChildren: [
                    <>
                        <DriscollsLogo />
                    </>
                ]
            }}
            navigation={{
                navigationItem: navigation
            }}
        />
    );
};

export default ApplicationLayout;
