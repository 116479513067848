export const POINT_MATRIX_SET_CONSTANTS = {
    DISTRICT_GROUP: 'DistrictGroup',
    INTRASTAT_CODE: 'IntrastatCode',
    COMMODITY_CODE: 'CommodityCode',
    ESTIMATE_GROUP: 'EstimateGroup',
    VARIETY: 'VarietyCode',
    VARIETY_DESCRIPTION: 'VarietyDescription',
    ATTRIBUTE_TYPE: 'AttributeType',
    MIN_POINT: 'MinPoint',
    MAX_POINT: 'MaxPoint',
    SPREAD: 'Spread',
    STATUS: 'Status',
    MODIFIED_BY: 'ModifiedBy',
    MODIFIED_DATE: 'ModifiedDatetime',
    DESCRIPTION: 'Description',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    ALL: 'All',
    DEFAULT: 'Default'
};

export const POINT_MATRIX_VARIANCE = {
    SCORE: 'Score',
    POINT: 'Point'
};

export const KEY_MAPPING = {
    MinScore: 'Min Score',
    VarietyCode: 'Variety',
    VarietyDescription: 'Variety Description',
    DistrictGroup: 'Country of Origin',
    EstimateGroup: 'Growing Method',
    CommodityCode: 'Commodity',
    MaxScore: 'Max Score',
    MaxPoint: 'Max Point',
    MinPoint: 'Min Point',
    ModifiedDatetime: 'Modified Date Time',
    CreatedDatetime: 'Created Date Time',
    CreatedBy: 'Created By',
    ModifiedBy: 'Modified By',
    AttributeType: 'Attribute Type',
    MidPoint: 'Mid Point',
    StepSize: 'Step Size',
    PointMatrixLookUpValues: 'PointMatrixLookUpValues',
    Point: 'Point',
    Spread: 'Spread',
    Status: 'Status',
    Label: 'Label',
    PoolWeek: 'Pool Week',
    PointMatrixId: 'PointMatrixId',
    PointMatrixLookupHistId: 'PointMatrixLookupHistId',
    Score: 'Score'
};
