import { DriscollsToast, DriscollsToastPosition, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { NAMESPACE } from '../../../i18n';
import style from './Toast.module.scss'
import { useApplicationContext } from '../../hooks/useApplicationContext';
const Toast = () => {
    const { showToast, hideToast } = useApplicationContext();

    const handleClose = () => {
        hideToast();
    };

    return !!showToast.toastMessage ? <DriscollsToast
        open={true}
        message={showToast.toastMessage}
        nameSpace={showToast.nameSpacing ?? NAMESPACE.COMMON}
        position={DriscollsToastPosition.BOTTOMRIGHT}
        onClose={handleClose}
        severity={showToast.severity ?? DriscollsToastSeverity.SUCCESS}
        containerClass={style.containerClass}
        alertClass={style.alertClass}
        autoHideDuration={5000}
    /> : null

};

export default Toast;
