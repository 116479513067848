import useGetApiTokens from './useGetApiTokens';
import axios from 'axios';
import useApis from './useApis';
import Parameters from '../services/api/Parameters';

const useGetUsers = () => {
    const { getAuthToken } = useGetApiTokens();
    const { getBaseURLMuleApis } = useApis();
    const gepUrl = 'https://gep-pub.dev.ds-api.io/gep/api/v1/';

    const fetchOktaUsersList = async (filter: string) => {
        try {
            const response = await axios.get(`${gepUrl}${Parameters.USERS}/${Parameters.USER_LIST}?limit=20&filter=${filter}`, {
                headers: { Authorization: getAuthToken() }
            });

            return response.data.map((result) => {
                const { email } = result.profile;
                return {
                    label: email,
                    value: email,
                    internalUserProfile: { oktaId: result.id, firstName: result.profile.firstName, lastName: result.profile.lastName }
                };
            });
        } catch (error) {
            console.log(error);
        }
    };

    return { fetchOktaUsersList };
};

export default useGetUsers;
