import { createContext } from 'react';
import { Persona } from '../shared/protected/PersonaBaseProtect';
import { IShowToast } from '../types/application';
interface IAppContext {
    userPersona: Persona | null;
    setUserPersona: (userPersona: Persona) => void;
    isMasterDataInitialized: boolean;
    setMasterDataInitialized: (isMasterDataInitialized: boolean) => void;
    pageTitle: string | null;
    setPageTitle: (lang: string) => void;
    redirectUrl: string | null;
    setInitializeRedirectUrl: (url: string) => void;
    showToast: IShowToast;
    showInfo: (message: string) => void;
    showWarning: (message: string) => void;
    showError: (message: string) => void;
    showSuccess: (message: string) => void;
    hideToast: () => void;
    showLoading: boolean;
    setShowLoading: (loading: boolean) => void;
}

const ApplicationContextProvider = createContext<IAppContext>({} as IAppContext);

export default ApplicationContextProvider;
