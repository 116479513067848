export const getAdminGroups = () => JSON.parse(window.config.OKTA_ADMIN_GROUPS) || [];
export const getManagerGroups = () => JSON.parse(window.config.OKTA_REGULAR_GROUPS) || [];
export const getAnalystGroups = () => JSON.parse(window.config.OKTA_READ_ONLY_GROUPS) || [];
export const getDemeaGroups = () => JSON.parse(window.config.OKTA_DEMEA_REGULAR_GROUPS) || [];

export const getManagerGroupsWithAdmin = () => [...getAdminGroups()].concat(getManagerGroups());
export const getAnalystGroupsWithAdmin = () => [...getAdminGroups()].concat(getAnalystGroups());
export const getDemeaGroupsWithAdmin = () => [...getAdminGroups()].concat(getDemeaGroups());

export const getAllGroups = () => [...getAdminGroups()].concat(getManagerGroups(), getAnalystGroups());
